.photo-bank {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.wrapper {
  display: flex;
  white-space: normal;
  width: 56vw;
  margin: 20px auto 0 auto;
  flex-wrap: wrap;
}

.wrapper .img-item {
  width: calc(56vw / 6 - 10px);
  height: calc(56vw / 6 - 10px);
  border-radius: 6px;
  display: block;
  margin: 0 10px 10px 0;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  user-select: none;
}

.wrapper .img-item img {
  width: 100%;
  height: 100%;
  user-select: none;
  object-fit: cover;
}

.wrapper .gou-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  object-fit: cover;
  width: 18px;
  height: 18px;
  user-select: none;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 12%) 0px 0px 6px 0px;
}

.wrapper .gou-icon img {
  width: 100%;
  height: 100%;
}

.tool-bar {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 0 0;
}

.title {
  font-size: 17px;
  font-weight: bold;
  color: #131415;
}

.info-right {
  display: inline-flex;
  align-items: center;
}

.checked-num {
  font-size: 14px;
  font-weight: 400;
  color: #fc8142;
  margin-right: 8px;
}

.download-btn,
.choose-all-btn {
  height: 30px;
  background: linear-gradient(315deg, #ffaa54 0%, #fc8142 100%);
  border-radius: 30px;
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
  display: block;
  width: 85px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  user-select: none;
  margin-right: 10px;
  font-weight: bold;
  letter-spacing: 1px;
}

.item-title {
  font-size: 14px;
  font-weight: bold;
  color: #131415;
  width: 56vw;
  margin: 0 auto;
}

.sub-num {
  font-size: 9px;
  font-weight: 400;
  color: #838391;
}

.warning-tips {
  text-align: center;
  font-size: 14px;
  color: #666;
}

.top-bar {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 999;
  padding: 10px 22vw 20px 22vw;
}
