body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.root {
  overflow: hidden;
}
